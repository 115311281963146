import React, { useState, useEffect } from 'react';
const WebPrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div className="menuCloseJS closeMenuWrapper">
                <div className="privacyTermsFlex privacyTermsColor privacyTermsScroll">
                    <div className="privacyTermsWidth">
                        <h1 className="privacyTermsHeading">Privacy</h1>
                        <div className="privacyTermsWrapper">
                            <div className="privacyTermsContent">
                                <p>
                                    Runway Beauty, Inc. values the privacy of our customers and takes reasonable measures to safeguard that information from unauthorized use. The following is a detailed explanation of our privacy policy with regard to the Validated web site and our related services. Your information is never shared for any reason .
                                </p>
                                <li> WHAT INFORMATION DO WE COLLECT?</li>
                                <p>
                                    We employ various methods for collecting information. To begin with, we use “cookies” that are needed to compile aggregate non-personally identifiable information about the visitors to our web site. Personally identifiable information consists of information that is unique to you, such as credit card numbers, bank accounts, social security numbers, home address, email address and the like. This is the type of information that most people consider private and deem the most important to protect from unauthorized access.
                                </p>
                                <p>Non-personally identifiable information most often consists of things such as your search preferences, the types of products you have bought, how many times you have visited a particular web site, etc. This type of information is usually associated with a particular computer or IP address but not with a particular person. “Cookies” are pieces of information generated by a web server and stored in the user’s computer, ready for future access. Each time you access our web site; our server finds the relevant cookie on your computer. The information contained within that cookie is then used to customize your experience with our site. The following is a list of the information we collect:</p>
                                <p>We automatically record the name of the domain (for example,”yahoo.com” if you use a private Internet access account, or “yourcollege.edu” if you are connecting from a university’s domain); the IP address (a number that is automatically assigned to your computer when you are using the Internet) from which you access our website; the type of browser and operating system used to access our site; the date and time you access our site; the Internet address of the website from which you linked directly to our site; and the pages you visit.</p>
                               
                                    <li>WHY DO WE COLLECT THIS INFORMATION?</li>
                                    <p>We use this information in order to serve the needs of our customers. We need to know who you are in order to verify the credit card information you have sent us and to conduct a thorough background check of you or your company. We collect several pieces of personal information from you in order to complete this verification process. The non-personal information that we collect from you is used for internal purposes to make your experience with our site more enjoyable. We may use the non-personal information we collect from our visitors/customers in an aggregate form to aid in our marketing efforts with third parties or to provide data to one of our affiliated companies. We may also use your email address to notify of you other products or services you may be interested in.</p>
                                    <li>HOW IS YOUR INFORMATION PROTECTED?</li>
                                    <p>We use the latest in encryption technology, including 2024 bit SSL encryption to ensure that any sensitive information that is sent by you to us via the Internet is protected from unauthorized interception. All information submitted by a customer via the Internet is stored on a server, which requires proper-authentication for access. The server is housed in a secure environment that employs a variety of techniques to protect your information. We also use anti-virus protection software to guard against outside attacks on our networks. All employees that we do hire have to sign a confidentiality agreement that forbids them from disclosing any information to which the employee has access, to other individuals or entities. We also have back up servers and power supplies to guard against power outages and other natural occurrences that could pose a threat to the integrity of your personal information. Passwords are required in order for you to gain access to your account. Once an account has been terminated, measures are taken to remove those users from the system who no longer need access to our system. Our servers are manned 24-hours a day, in a building that is UL approved for fire protection. Any information submitted by a customer by other means such as the mail, or by facsimile, is stored in a secure area that requires a key to gain access to. Access to this information is limited to only those employees who need the information in order to do their job effectively.</p>
                                    
                                <p style={{fontWeight:"800"}}>Confidentiality Notice: </p>
                               <p>Any e-mail messages sent to you from any address at RunwayTV.com, including any attachments, is for the sole use of the intended recipient(s) and may contain confidential and privileged information. Any unauthorized review, use, disclosure or distribution is prohibited. If you are not the intended recipient, please contact the sender by reply e-mail and destroy all copies of the original message.</p>
                            <p style={{fontWeight:"800"}}>Use of this Site </p>
                            <p>This Site and all its Contents are intended solely for personal, non-commercial use. You may download or copy the Contents and other downloadable materials displayed on the Site for your personal use only. No right, title or interest in any downloaded materials or software is transferred to you as a result of any such downloading or copying. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of or exploit in any way, in whole or in part, any of the Contents, the Site or any related software. All software used on this site is the property of RunwayTV.com or its suppliers and protected by U.S. and international copyright laws. The content and software on this site may be used only as a shopping resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content on this site is strictly prohibited. RunwayTV.com is a registered trademark of Runway Beauty, Inc..</p>
                            
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WebPrivacyPolicy;
