import React, { useState, useEffect } from 'react';
const WebTermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div className="menuCloseJS closeMenuWrapper">
                <div className="privacyTermsFlex privacyTermsColor">
                    <div className="privacyTermsWidth">
                        <h1 className="privacyTermsHeading">Terms of Use</h1>
                        <div className="privacyTermsWrapper">
                            <div className="privacyTermsContent">
                               <p>Welcome to RunwayTV.com. This site is provided by Runway Beauty, Inc (referred to throughout this site as “RunwayTV.com”) as a service to our customers. Please review the following basic rules that govern your use of our Site (the “Agreement”). Please note that your use of our Site constitutes your unconditional agreement to follow and be bound by these Terms and Conditions. Although you may “bookmark” a particular portion of this Site and thereby bypass this Agreement, your use of this Site still binds you to the Terms. RunwayTV.com reserves the right to update or modify these Terms and Conditions at any time without prior notice. Your use of the RunwayTV.com Web site following any such change constitutes your unconditional agreement to follow and be bound by the Terms and Conditions as changed. For this reason, we encourage you to review these Terms and Conditions whenever you use this Web site.</p>
                            <li style={{fontWeight:"800", color:"black"}}>Site Security </li>
                            <p>Users are prohibited from violating or attempting to violate the security of the Site, including, without limitation, (a) accessing data not intended for such user or logging onto a server or an account which the user is not authorized to access; (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (c) attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting a virus to the Site, overloading, “flooding,” “spamming,” “mailbombing” or “crashing;” (d) sending unsolicited email, including promotions and/or advertising of products or services; or (e) forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting. Violations of system or network security may result in civil or criminal liability. RunwayTV.com will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Site or any activity being conducted on this site. You agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search this Site other than the search engine and search agents available from RunwayTV.com on this Site and other than generally available third party web browsers (e.g., Netscape Navigator, Microsoft Explorer).</p>
                            <li style={{fontWeight:"800", color:"black"}}>Order Acceptance</li>
                            <p>Please note that there may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. For your convenience, you will not be charged until your payment method is authorized, the order information is verified for accuracy and your order is shipped. Some situations that may result in your order being canceled include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is canceled or if additional information is required to accept your order. If your order is canceled after your credit card has been charged, we will issue a credit to your credit card in the amount of the charge.

</p>
                            
                       <li style={{fontWeight:"800", color:"black"}}>
                       Pricing Information   </li>     
                          <p>While RunwayTV.com strives to provide accurate product and pricing information, pricing or typographical errors may occur. RunwayTV.com cannot confirm the price of an item until after you order. In the event that an item is listed at an incorrect price or with incorrect information due to an error in pricing or product information, RunwayTV.com shall have the right, at our sole discretion, to refuse or cancel any orders placed for that item. In the event that an item is mispriced, RunwayTV.com may, at our discretion, either contact you for instructions or cancel your order and notify you of such cancellation.</p>  
                            <p>We strive to provide you with the lowest prices possible on RunwayTV.com as well as in our stores. However, sometimes a price online does not match the price in a store. In our effort to be the lowest price provider in your particular geographic region, store pricing will sometimes differ from online prices.Prices and availability are subject to change without notice.</p>
                           <li style={{fontWeight:"800", color:"black"}}>Quantity Limits & Dealer Sales</li>
                           <p>RunwayTV.com reserves the right to limit the quantity of items purchased per person, per household or per order. These restrictions may be applicable to orders placed by the same Vipsalon account, the same credit card, and also to orders that use the same billing and/or shipping address. We will provide notification to the customer e-mail and/or billing address provided should such limits be applied. RunwayTV.com also reserves the right to prohibit sales to dealers. If you are interested in purchasing multiple quantities of items for a corporation or institution, please contact us by email at service@vipsalononline.com. Top of Page General</p>
                           <p>This Agreement represents the complete agreement between the parties and supersedes all prior agreementsand representations between them. If any provision of this Agreement is held to be unenforceable for any reason, such provision shall be reformed only to the extent necessary to make it enforceable and the other terms of this Agreement shall remain in full force and effect. The failure of RunwayTV.com to act with respect to a breach of this Agreement by you or others does not constitute a waiver and shall not limit RunwayTV.com’s rights with respect to such breach or any subsequent breaches. This Agreement shall be governed by and construed under California law as such law applies to agreements between California residents entered into and to be performed within California. Any action or proceeding arising out of or related to this Agreement or your use of this Site must be brought in the state or federal courts of California.</p>
                           <li style={{fontWeight:"800", color:"black"}}>User Reviews, Feedback, Submissions</li>
<p>All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or offered to RunwayTV.com on or by this Site or otherwise disclosed, submitted or offered in connection with your use of this Site (collectively, the “Comments”) shall be and remain RunwayTV.com’s property. Such disclosure, submission or offer of any Comments shall constitute an assignment to RunwayTV.com of all worldwide rights, titles and interests in all copyrights and other intellectual properties in the Comments.</p>
<p>Thus, RunwayTV.com will own exclusively all such rights, titles and interests and shall not be limited in any way in its use, commercial or otherwise, of any Comments. RunwayTV.com will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and distribute any Comments you submit for any purpose whatsoever, without restriction and without compensating you in any way.</p>
<p>RunwayTV.com is and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay to user any compensation for any Comments; or (3) to respond to any user Comments. You agree that any Comments submitted by you to the Site will not violate this policy or any right of any third party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no Comments submitted by you to the Site will be or contain libelous or otherwise unlawful, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of “spam”.
RunwayTV.com does not regularly review posted Comments, but does reserve the right (but not the obligation) to monitor and edit or remove any Comments submitted to the Site. You grant RunwayTV.com the right to use the name that you submit in connection with any Comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are and shall remain solely responsible for the content of any Comments you make and you agree to indemnify RunwayTV.com and its affiliates for all claims resulting from any Comments you submit. RunwayTV.com and its affiliates take no responsibility and assume no liability for any Comments submitted by you or any third party.</p>
             <li style={{fontWeight:"800", color:"black"}}>Indemnification</li>               
                  <p>You agree to defend, indemnify and hold RunwayTV.com harmless from and against any and all claims, damages, costs and expenses, including attorneys’ fees, arising from or related to your use of the Site.</p>          
                    <li style={{fontWeight:"800", color:"black"}}>Termination</li>       
                     <p>These terms are effective unless and until terminated by either you or RunwayTV.com. You may terminate this Agreement at any time, provided that you discontinue any further use of this Site. RunwayTV.com also may terminate this Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Site, if in RunwayTV.com’s sole discretion you fail to comply with any term or provision of this Agreement. Upon any termination of the Agreement by either you or RunwayTV.com, you must promptly destroy all materials downloaded or otherwise obtained from this Site, as well as all copies of such materials, whether made under the terms of use or otherwise.These terms are effective unless and until terminated by either you or RunwayTV.com. You may terminate this Agreement at any time, provided that you discontinue any further use of this Site. RunwayTV.com also may terminate this Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Site, if in RunwayTV.com’s sole discretion you fail to comply with any term or provision of this Agreement. Upon any termination of the Agreement by either you or RunwayTV.com, you must promptly destroy all materials downloaded or otherwise obtained from this Site, as well as all copies of such materials, whether made under the terms of use or otherwise.</p>       
                         <li style={{fontWeight:"800", color:"black"}}>Disclaimer</li>   
<p>THIS SITE IS PROVIDED BY RUNWAY BEAUTY, INC. ON AN “AS IS” AND “AS AVAILABLE” BASIS. RUNWAYTV.COM MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, RUNWAYTV.COM DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, RUNWAYTV.COM DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, FOR ANY MERCHANDISE OFFERED ON THIS SITE. YOU ACKNOWLEDGE, BY YOUR USE OF THE RUNWAYTV.COM WEB SITE, THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THIS DISCLAIMER DOES NOT APPLY TO ANY PRODUCT WARRANTY OFFERED BY THE MANUFACTURER OF THE ITEM. THIS DISCLAIMER CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.</p>
<li style={{fontWeight:"800", color:"black"}}>Limitation of Liability</li>
<p>UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE, SHALL RUNWAYTV.COM OR ANY OF ITS AFFILIATES, EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, VENDORS OR SUPPLIERS BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR INABILITY TO USE THE RunwayTV.com WEB SITE, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF AN AUTHORIZED REPRESENTATIVE OF RUNWAYTV.COM HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL RUNWAYTV.COM BE LIABLE FOR ANY DAMAGES IN EXCESS OF THE FEES PAID BY YOU IN CONNECTION WITH YOUR USE OF THE SITE DURING THE SIX MONTH PERIOD PRECEDING THE DATE ON WHICH THE CLAIM AROSE.</p>
<li style={{fontWeight:"800", color:"black"}}>Copyrights and Trademarks </li>
<p>This Internet site is not sponsored, endorsed by or otherwise affiliated with any Manufacturer, Products or Brand Names being offered within this website. Manufacturers are the Owners of the Registered Trademarks, Trade Dress and Copyrights in said Products and Trademarks.</p>
        <p style={{fontWeight:"800", color:"black"}}>© 2022 Runway Beauty, INC. All Rights Reserved.</p>                    
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WebTermsOfUse;
