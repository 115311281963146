import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link, useHistory, useLocation } from "react-router-dom";
import SubscriptionContainer from "./SubscriptionContainer";
import { service } from "../../network/service";
import "./Payment.css";
import { loadStripe } from "@stripe/stripe-js";
import stripeImage from "../../img/stripe.jpeg";
import mastercard from "../../img/mastercard.png";
import visa from "../../img/visa.png";
import american from "../../img/american.png";
import paypal from "../../img/payp.png";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { clearUserData } from "../../Utils/utils";
import parse from "html-react-parser";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);
const Payment = (state) => {
  const history = useHistory();
  const isLoggedIn = service.getCookie("isLoggedIn");
  const [androidData, setAndroidData] = useState(null);
  const [isAndroid, setIsAndroid] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [paypalLink, setPaypalLink] = useState("");
  const [subscription, setSubscription] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(false);
  const [isCouponEnabled, setIsCouponEnabled] = useState(false);
  const [buttonId, setButtonId] = useState()




  const [Invalid, setInvalid] = useState(false);
  const [notExist, setnotExist] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState("");



  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.location.state) {
      setAndroidData(state.location.state.androidData);
      setPaymentData(state.location.state.paymentData);
      setIsAndroid(state.location.state.isAndroid);
      localStorage.setItem("deviceType", "web");
      if (state.location.state.isAndroid) {
        localStorage.setItem("isAndroid", state.location.state.isAndroid);
        localStorage.setItem("deviceType", "android-web");
      }
      let tempData = state.location.state.paymentData;
      let deviceType = localStorage.getItem("deviceType");
      let userId = service.getCookie("userId");
      let countryCode = service.getCookie("country_code");
      service.setCookie("subId", tempData.publisher_subscription_id, 10);
      localStorage.setItem("selectedSubId", tempData.publisher_subscription_id);
      console.log("data of subs", tempData);
      var user_data = {
        transaction_type: 1,
        subscription_id: tempData.publisher_subscription_id,
        uid: userId,
        amount: tempData.price,
        pubid: process.env.REACT_APP_PUBID,
        country_code: countryCode,
        device_type: deviceType,
      };
      var user_details = encodeURIComponent(JSON.stringify(user_data));
      let link = `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${tempData.paypal_keyword}&custom=${user_details}&discount=10&currency_code=USD&upload=1`;
      // let link = `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${tempData.paypal_keyword}&custom=${user_details}&discount=10&currency_code=USD&upload=1`;
      setPaypalLink(link);
      setButtonId(tempData.paypal_keyword)
      // setButtonId("P-60K23766823505746MPWHUWQ") // for testing
    } else if (isLoggedIn == "true") {
      // history.push("/home");
    }
    var singleObj = [];
    let userId = service.getCookie("userId");
    service.userSubscription(userId).then((response) => {
      if (response.success == true) {
        if (response.forcibleLogout === true) {
          alert(
            "Sorry, You’ve reached the maximum Device limit. Please log in again!"
          );
          service.logoutAll(userId).then((res) => {
            setTimeout(() => {
              redirectToLogin();
            }, 1000);
          });
        } else if (response.session_expired === true) {
          ToastsStore.warning("Sorry! Session Has Expired");
          redirectToLogin();
        } else {
          var data = response.data;
          data.map((item, index) => {
            singleObj.push(item);
          });
          setSubscription(singleObj);
        }
      }
    });
  }, []);

  useEffect(() => {
    localStorage.removeItem("fromVideoplayer");
    localStorage.removeItem("selected_amount");
  }, []);

  const handleCoupon = (e) => {
    setCoupon(e.target.value);
  };

  const applyCoupons = async () => {
    service.couponCode(coupon).then((response) => {
      if (response.data) {
        if (response.data.coupon_id) {
          let coupon_id = response.data.coupon_id;
          localStorage.setItem("coupon_id", coupon_id);
          setIsCouponEnabled(true);
          setSuccess(true);
          setnotExist(false);
          setInvalid(false);
          setCouponNotValid(false);
          setDiscountedPrice(true);
          setPaymentResponse(response.data.discounted_price);
          let selected_amount = response.data.discounted_price;
          localStorage.setItem("selected_amount", selected_amount);
        }
      } else if (response.message == "Coupon does not exist") {
        setnotExist(true);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      } else if (
        response.message == "Coupon is not valid for this subscription."
      ) {
        setnotExist(false);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(true);
        setDiscountedPrice(false);
      } else {
        setInvalid(true);
        setSuccess(false);
        setnotExist(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      }
    });
  };

  const redirectToLogin = () => {
    // dispatch({ type: "LOGOUT" });
    // setMouseHover(false);
    clearUserData();
    setTimeout(() => {
      let isAndroid = localStorage.getItem("isAndroid");
      if (isAndroid == true || isAndroid == "true") {
        window.location.href = process.env.REACT_APP_WEB_VIEW_FAILED;
      } else {
        window.location.href = "/signin";
      }
    }, 500);
  };

  // const onClickPaypalButton = (link) => {
  //   if (coupon) {
  //     alert("Coupon Code is not applicable for PayPal!");
  //   } else {
  //     window.open(link, "_self");
  //   }
  // };

  const handleClick = async (event) => {
    localStorage.setItem(
      "selectedSubId",
      paymentData.publisher_subscription_id
    );
    localStorage.setItem("selectedAmount", paymentData.price);
    const session = await service.stripeSession(
      paymentData.publisher_subscription_id
    );
    ;
    console.log("session", session);
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  if (!isLoggedIn || isLoggedIn == "false") {
    return <Redirect push to="/signin" />;
  }

  if (!buttonId) {
    return null
  } else {

    //   var htmlCode = `<div style="display: flex ; justify-content: center ; align-items: center" id="paypal-button-container-${buttonId}"></div>
    //   <script src="https://www.paypal.com/sdk/js?client-id=AU28xcDxIFnD7LL_g98F2atJvC2SU1_a0zGrFMGhW2SAqvJbWG2Alc0_WOJ3vqo1szBOoTEvJx_YYsmu&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>  // <script src="https://www.paypal.com/sdk/js?client-id=AQ3qWv1-6UEVUN2X9_OJJVQECzwdyT1RywV5qpwWwaWUi0GRC9RUKa_iRYBciiEwq6LA_znq82GMab8S&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script><script>
    //   // ^testing


    // function transactionApi(id , cb){
    //   console.log("API function called")
    //   var xhttpPost = new XMLHttpRequest();
    //   var url = '${process.env.REACT_APP_API_URL}subscription/updateTransaction';
    //   var body = {
    //     device_id: localStorage.getItem('deviceId'),
    //   transaction_type: 1,
    //   subscription_id: localStorage.getItem("selectedSubId"),
    //   mode_of_payment: "paypal",
    //   status: "success",
    //   uid: localStorage.getItem('userId'),
    //   amount: ${paymentData.price},
    //   receiptid: id,
    //   pubid: '${process.env.REACT_APP_PUBID}',
    //   device_type: localStorage.getItem('deviceType'),
    // }

    //         xhttpPost.onload = function (e) {
    //       cb(this.responseText);

    //     };

    //   xhttpPost.onerror = function (e) {
    //       // cacheValue(errorTextKey, networkRequestFailedText);
    //       // cb(null);
    //   };

    //   xhttpPost.ontimeout = function (e) {
    //       xhttpPost.abort();

    //       cacheValue(errorTextKey, networkRequestTimeoutText);
    //       cb(null);
    //   }

    //   xhttpPost.open('POST', url, true);
    //   xhttpPost.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    //   xhttpPost.setRequestHeader('Access-Control-Allow-Origin', true);
    //   xhttpPost.setRequestHeader('crossorigin', '*');
    //   xhttpPost.setRequestHeader('pubid', ${process.env.REACT_APP_PUBID});
    //   xhttpPost.setRequestHeader('channelid', ${process.env.REACT_APP_CHANNELID});
    //   xhttpPost.setRequestHeader('access-token', localStorage.getItem("access-token"));
    //   xhttpPost.setRequestHeader('uid', localStorage.getItem('userId'));
    //   xhttpPost.setRequestHeader('country_code', localStorage.getItem('country_code'));
    //   xhttpPost.setRequestHeader('dev_id', localStorage.getItem('deviceId'));
    //   xhttpPost.setRequestHeader('device_type', localStorage.getItem('deviceType'));
    //   xhttpPost.setRequestHeader('ip', localStorage.getItem('ipaddress'));


    //   xhttpPost.send(JSON.stringify(body));  
    // }


    //   paypal.Buttons({
    //       style: {
    //           shape: 'pill',
    //           color: 'blue',
    //           layout: 'vertical',
    //           label: 'paypal'
    //       },
    //       createSubscription: function(data, actions) {
    //         return actions.subscription.create({
    //           /* Creates the subscription */
    //           plan_id: '${buttonId}'
    //         });
    //       },

    //   }).render('#paypal-button-container-${buttonId}'); // Renders the PayPal button
    // </script>`

    var htmlCode = `<div style="display: flex ; justify-content: center ; align-items: center" id="paypal-button-container-${buttonId}"></div>
  <script src="https://www.paypal.com/sdk/js?client-id=AU28xcDxIFnD7LL_g98F2atJvC2SU1_a0zGrFMGhW2SAqvJbWG2Alc0_WOJ3vqo1szBOoTEvJx_YYsmu&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
  <script>
  function transactionApi(id , cb){
      console.log("API function called")
      var xhttpPost = new XMLHttpRequest();
      var url = '${process.env.REACT_APP_API_URL}subscription/updateTransaction';
      var body = {
        device_id: localStorage.getItem('deviceId'),
        transaction_type: 1,
        subscription_id: localStorage.getItem("selectedSubId"),
        mode_of_payment: "paypal",
        status: "success",
        uid: localStorage.getItem('userId'),
        amount: ${paymentData.price},
        receiptid: id,
        pubid: '${process.env.REACT_APP_PUBID}',
        device_type: localStorage.getItem('deviceType'),
        coupon_id: localStorage.getItem('coupon_id'),
      }
  
        xhttpPost.onload = function (e) {
          cb(this.responseText);
          
        };
  
      xhttpPost.onerror = function (e) {
          // cacheValue(errorTextKey, networkRequestFailedText);
          // cb(null);
      };
  
      xhttpPost.ontimeout = function (e) {
          xhttpPost.abort();
  
          cacheValue(errorTextKey, networkRequestTimeoutText);
          cb(null);
      }
      
      xhttpPost.open('POST', url, true);
      xhttpPost.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhttpPost.setRequestHeader('Access-Control-Allow-Origin', true);
      xhttpPost.setRequestHeader('crossorigin', '*');
      xhttpPost.setRequestHeader('pubid', ${process.env.REACT_APP_PUBID});
      xhttpPost.setRequestHeader('channelid', ${process.env.REACT_APP_CHANNELID});
      xhttpPost.setRequestHeader('access-token', localStorage.getItem("access-token"));
      xhttpPost.setRequestHeader('uid', localStorage.getItem('userId'));
      xhttpPost.setRequestHeader('country_code', localStorage.getItem('country_code'));
      xhttpPost.setRequestHeader('dev_id', localStorage.getItem('deviceId'));
      xhttpPost.setRequestHeader('device_type', localStorage.getItem('deviceType'));
      xhttpPost.setRequestHeader('ip', localStorage.getItem('ipaddress'));
  
  
      xhttpPost.send(JSON.stringify(body));  
    }
  
    paypal.Buttons({
        style: {
            shape: 'rect',
            color: 'blue',
            layout: 'vertical',
            label: 'subscribe'
        },
        createSubscription: function(data, actions) {
          return actions.subscription.create({
            /* Creates the subscription */
            plan_id: '${buttonId}'
          });
        },
        onApprove: function(data, actions) {

          transactionApi(data.subscriptionID, function (response) {
            if (response) {
              // alert(data.subscriptionID)
              actions.redirect('https://watch.runwaytv.com/success')
            }
          })
        
        }
    }).render('#paypal-button-container-${buttonId}'); // Renders the PayPal button
  </script>`
    console.log("buttonid" + buttonId);





    return (
      <div className="pageWrapper searchPageMain payment__container marginAdjust" >
        <div className="topContainer">
          <div className="homepageWrapper menuCloseJS closeMenuWrapper">
            <div className="container">
              <p className="text-head"  >Payment Gateway</p>

              {/* ################ Coupon code field ################ */}
              {/* <div>
              <p
                style={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: "20px",
                  paddingLeft: "1%",
                }}
              >
                {" "}
                Coupon Code :{" "}
              </p>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <input
                  className="coupon_input"
                  type="text"
                  value={coupon}
                  onChange={handleCoupon}
                  style = {{border:'2px solid white', color : 'white'}}
                />
                <button
                  onClick={applyCoupons}
                  style={{
                    height: "44px",
                    width: "90px",
                    fontSize: "15px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "orange",
                  }}
                  type="submit"
                >
                  Apply
                </button>
              </span>
              {success && (
                <p style={{ color: "green" }}>
                  Coupon Code applied successfully
                </p>
              )}
              {discountedPrice && (
                <p style={{ color: "white" }}>
                  Grand Total : {paymentResponse}
                </p>
              )}

              {notExist && (
                <p style={{ color: "red" }}>Coupon Code does not exist</p>
              )}
              {couponNotValid && (
                <p style={{ color: "red" }}>
                  Coupon is not valid for this subscription
                </p>
              )}
              {Invalid && (
                <p style={{ color: "red" }}>
                  Entered coupon code has expired
                </p>
              )}
            </div> */}

              <fieldset className="myFieldSet">
                <legend className="payment-box">
                  Select a payment gateway
                </legend>

                {/* ################ new code ################ */}
                {/* {parse(htmlCode)} */}
                <iframe style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '200px' , border: 'none' }} srcDoc={htmlCode}></iframe>

                {/* ################ */}




                {/* <img
                className="cards1"
                style={{ marginLeft: "15px" }}
                src={mastercard}
                onClick={handleClick}
              ></img>
              <img
                className="cards1"
                style={{ marginLeft: "15px" }}
                src={visa}
                onClick={handleClick}
              ></img>
              <img
                className="cards1"
                style={{ marginLeft: "15px" }}
                src={american}
                onClick={handleClick}
              ></img> */}

                {/* ################ last used code ################ */}
                {/* <a href={paypalLink}>
                <img className="cards1" src={paypal}></img>
              </a> */}

                {/* ################ */}

                {/* <div  onClick={onClickPaypalButton(paypalLink)}> */}
                {/* <img className="cards1" src={paypal}></img> */}
                {/* </div> */}
              </fieldset>
              <SubscriptionContainer param={subscription} />
            </div>
            <ToastsContainer store={ToastsStore} />
          </div>
        </div>
      </div>
    );
  }

};
export default Payment;


