import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { service } from "../../network/service";
const Success = () => {
  const history = useHistory();
  const [fromVP, setFromVP] = useState(false);


  useEffect(() => {
    let prevurl = localStorage.getItem("fromVideoplayer");
    if (prevurl == "true") {
      const vshowId = service.getCookie("showId");
      if (vshowId) {
        history.push({
          pathname: "/home/movies",
          search: encodeURI(`show_id=${vshowId}`),
        });
      } else {
        history.push({
          pathname: "/home",
        });
      }
    } else {
      setFromVP(true);
    }

    localStorage.removeItem("referalId")
    localStorage.removeItem("coupon_id")

    let isAndroid = localStorage.getItem("isAndroid");
    if (isAndroid == "true") {
      window.location.href = process.env.REACT_APP_WEB_VIEW_SUCCESS;// android succes redirect
    }


  }, []);
  const handleClick = () => {
    ;
    let showId = service.getCookie("showId");
    let videoId = service.getCookie("videoId");
    localStorage.removeItem("referalId")
    let videoDetails;
    console.clear();
    if (showId && videoId) {
      service.getVideoDetails(videoId).then((response) => {
        if (response.success == true && response.data) {
          videoDetails = response.data;
          history.push({
            pathname: "/videoplayer",
            state: {
              show_details: videoDetails,
              // singleVideo: 0,
              showId: showId,
            },
          });
        } else {
          history.replace({
            pathname: "/home",
          });
        }
      });
    } else if (showId) {
      history.replace({
        pathname: "/home/movies",
        search: encodeURI(`show_id=${showId}`),
      });
    } else {
      history.replace({
        pathname: "/home",
      });
    }
  };
  return (
    <div className="pageWrapper searchPageMain marginAdjust">
      {fromVP == true ? (
        <div className="topContainer" style={{ minHeight: "71vh" }}>
          <div className="homepageWrapper menuCloseJS closeMenuWrapper">
            <div className="container" style={{ marginTop: "170px" }}>
              <div
                className="card"
                style={{
                  width: "fit-content",
                  border: "none",
                  boxShadow: "none",
                }}>
                <div className="mycontainer">
                  <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="icon-box">
                          <i className="material-icons">&#xE876;</i>
                        </div>
                        <h4 className="modal-title w-100">Success!</h4>
                      </div>
                      <div className="modal-body">
                        <p className="text-center">
                          Your payment has been confirmed. Enjoy the videos.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          className="myBtn1 btn-success btn-block"
                          onClick={handleClick}
                          data-dismiss="modal">
                            OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default Success;
